<template>
<vs-card>
    <div slot="header" class="flex flex-no-wrap w-full justify-between items-center">
      <h4>{{ this.name }}'s Permission</h4>
      <vs-button @click="saveData" v-if="organizationId">Save</vs-button>
    </div>
    <div class="mt-5" v-if="adminToggle">
      <b>Admin permissions are turned on</b>
    </div>
    <div class="mt-5" v-else>
      <b
        >Enable admin permissions to give this staff member access to the below
        section in your workspace.</b
      >
    </div>
    <div class="w-full my-4">
      <vs-select
        label="Organisation"
        placeholder="Select an Organisation"
        autocomplete
        v-model="organizationId"
        class="sm:w-1/2 md:w-1/4 w-full"
      >
        <vs-select-item
          :key="index"
          :value="item.value"
          :text="item.text"
          v-for="(item, index) in organizationList"
        />
      </vs-select>
    </div>
    <vs-switch
      class="mb-4"
      @change="permissionChange"
      v-model="adminToggle"
      :disabled="organizationId ? false : true"
    />
    <vs-checkbox
      v-for="(item, index) in permissionList"
      :key="index"
      :class="!adminToggle ? 'mt-2 opacity-50' : 'mt-2'"
      :disabled="!adminToggle"
      v-model="permissions_on"
      :vs-value="item.value"
      >{{ item.text }}</vs-checkbox
    >
    <div
      v-if="checklist"
      class="con-text-validation span-text-validation-danger vs-input--text-validation-span"
    >
      <span class="span-text-validation"
        >Check at least one(1) permission or turn off admin permission</span
      >
    </div>
    <!-- <div class="flex lg:hidden mt-16">
      <vs-button @click="saveData" class="w-full">Save</vs-button>
    </div> -->
  </vs-card>
</template>

<script>
import { mapActions } from "vuex";
import _ from "lodash";
export default {
  name: "PermissionSettings",
  props: ["userId", "userType"],
  data() {
    return {
      name: null,
      adminToggle: false,
      organizationId: null,
      permissions_on: [],
      permissions_off: [],
      checklist: false,
      organizationList: [],
      permissionList: [
        {
          value: "Staff",
          text: "Staff management",
        },
        {
          value: "Clinic",
          text: "Clinic management",
        },
        {
          value: "Invoice",
          text: "Invoice management",
        },
        {
          value: "Drug",
          text: "Drugbook management",
        },
        {
          value: "Shop",
          text: "Shop - Purchase products",
        },
        {
          value: "Compliance",
          text: "Monthly Compliance",
        },
        {
          value: "Settings",
          text: "Settings management",
        },
        {
          value: "POS",
          text: "POS",
        },
        {
          value: "Insights",
          text: "Insights",
        },
      ],
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 25,
        filter: "All",
        id: "",
      },
    };
  },
  methods: {
    ...mapActions("admin", ["fetchUserPermission", "updateUserPermission"]),
    ...mapActions("organization", ["fetchAssociatedOrganizations"]),
    ...mapActions("doctor", ["fetchDoctorDetail"]),
    ...mapActions("nurse", ["fetchNurseDetails"]),
    ...mapActions("basicUser", ["fetchBasicUserDetails"]),
    async getOrganizationList() {
      try {
        this.$vs.loading();
        const res = await this.fetchAssociatedOrganizations(
          this.dataTableParams
        );
        if (res.status === 200) {
          const data = res.data.data.docs
            .filter((e) => e.status)
            .map((item) => {
              return {
                value: item._id,
                text: item.name,
              };
            });
          this.organizationList = data;
          if (data.length && !this.organizationId) {
            this.organizationId = data[0].value;
          }
        }
        this.$vs.loading.close();
      } catch (error) {
        console.error("ERR: ", error);
        this.$vs.loading.close();
      }
    },
    async getDoctorDetails() {
      try {
        this.$vs.loading();
        const res = await this.fetchDoctorDetail(this.userId);
        if (res.status === 200) {
          this.name = res.data.data.firstName;
        }
        this.$vs.loading.close();
      } catch (error) {
        console.error("DOC ERR: ", error);
        this.$vs.loading.close();
      }
    },
    async getNurseDetails() {
      try {
        this.$vs.loading();
        const res = await this.fetchNurseDetails(this.userId);
        if (res.status === 200) {
          this.name = res.data.data.firstName;
        }
        this.$vs.loading.close();
      } catch (error) {
        console.error("NURSE ERR: ", error);
        this.$vs.loading.close();
      }
    },
    async getBasicUserDetails() {
      try {
        this.$vs.loading();
        const res = await this.fetchBasicUserDetails(this.userId);
        if (res.status === 200) {
          this.name = res.data.data.firstName;
        }
        this.$vs.loading.close();
      } catch (error) {
        console.error("BASIC ERR: ", error);
        this.$vs.loading.close();
      }
    },
    getUserPermission() {
      this.$vs.loading();
      this.fetchUserPermission({
        userId: this.userId,
        organizationId: this.organizationId,
      })
        .then((res) => {
          const val = res.data;
          if (val.length > 0) {
            this.permissions_on = val.map((perm) => {
              return perm.permission.name;
            });
            this.adminToggle = true;
          } else {
            this.permissions_on = [];
            this.adminToggle = false;
          }
          this.$vs.loading.close();
        })
        .catch((err) => {
          console.error("error", err);
          this.$vs.loading.close();
        });
    },
    permissionChange() {
      if (!this.adminToggle) {
        this.permissions_on = [];
        this.checklist = false;
      }
    },
    saveData() {
      if (this.adminToggle && this.permissions_on.length === 0) {
        this.checklist = true;
      } else {
        this.checklist = false;
        this.$vs.loading();
        let permissionsList = [
          "Invoice",
          "Staff",
          "Clinic",
          "Shop",
          "Settings",
          "Compliance",
          "Drug",
          "POS",
          "Insights"
        ];
        if (this.isUSRegion) {
          permissionsList = _.filter(permissionsList, (e) => e !== "Drug");
        }
        this.permissions_off = _.difference(
          permissionsList,
          this.permissions_on
        );
        const payload = {
          userId: this.userId,
          organizationId: this.organizationId,
          permissions_on: this.permissions_on,
          permissions_off: this.permissions_off,
        };
        this.updateUserPermission(payload)
          .then((res) => {
            if (res.status === 200) {
              this.$vs.notify({
                title: "Success",
                text: "Successfully updated user permissions.",
              });
              this.$vs.loading.close();
            }
          })
          .catch((err) => {
            this.$vs.notify({
              title: "Error",
              text: "Failed to update user permissions.",
              color: "danger",
            });
          });
        this.$vs.loading.close();
      }
    },
    getChecklist() {
      if (this.adminToggle && this.permissions_on.length === 0) {
        this.checklist = true;
      }
      this.checklist = false;
    },
    removePOSOption() {
      this.permissionList = _.filter(
        this.permissionList,
        (e) => e.value !== "POS"
      );
    }
  },
  computed: {
    isUSRegion() {
      return process.env.VUE_APP_REGION === "US";
    },
  },
  watch: {
    permissions_on: {
      handler() {
        this.getChecklist();
      },
    },
    organizationId: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getUserPermission();
      }
    },
  },
  created() {
    this.dataTableParams.id = this.userId;
    if (this.isUSRegion) {
      this.permissionList = _.filter(
        this.permissionList,
        (e) => e.value !== "Drug"
      );
    }
    if (this.userType === "doctor") {
      this.getDoctorDetails();
      this.removePOSOption();
    } else if (this.userType === "nurse") {
      this.getNurseDetails();
    } else if (this.userType === "basic") {
      this.getBasicUserDetails();
      this.removePOSOption();
    }
    this.getOrganizationList();
  },
};
</script>
